@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;800&display=swap');

html {
	height: 100vh;
}

body {
	min-height: 100vh;
	font-size: 14px;
	font-family: 'Nunito Sans', 'Source Sans Pro', sans-serif;
	font-weight: 400;
}

/*@import "backgrounds.scss";
@import "buttons.scss";
@import "cards.scss";
@import "modal.scss";
@import "images.scss";
@import "navbarcomponent.scss";

//elements
@import "elements/search.scss";
@import "elements/cookieconsent.scss";
@import "elements/imagemanager.scss";
@import "elements/footer.scss";


//pages
@import "pages/signin.scss";
@import "pages/user.scss";
@import "pages/mainpage.scss";
@import "pages/chat.scss";
@import "pages/netzwerk.scss";
@import "pages/companies.scss";
@import "pages/companiespublic.scss";
@import "pages/newcompany.scss";
@import "pages/jitsipage.scss";
@import "pages/feedback.scss";
@import "pages/profile.scss";
@import "pages/currentcompany.scss";
@import "pages/aboutus.scss";
@import "pages/invitationpage.scss";
@import "pages/landingpage.scss";
@import "pages/crosspage.scss";*/

@import "pages/mainpage.scss";


@media (max-width: 575.98px) {

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}
