.title-text {
  height: 32px;
  width: 376px;
  color: #30445B;
  font-family: "Work Sans";
  font-size: 33px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

.normal-text {
  height: 64px;
  width: 376px;
  color: #808080;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}



.call-btn {
  height: 68.08px;
  width: 430px;
  border-radius: 34.04px;
  background-color: #00A441;
}
.call-btn-text {
  height: 68px;
  width: 170.2px;
  color: #FFFFFF;
  font-family: "Work Sans";
  font-size: 23.68px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 68.08px;
  text-align: center;
}

.home-text {
  height: 45px;
  width: 170.2px;
  color: #FFFFFF;
  font-family: "Work Sans";
  font-size: 23.68px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  display: inline-block;
}

.ebike-btn {
  height: 60px;
  width: 185px;
  border-radius: 30px;
  background-color: #00A441;
  display: inline-block;
  padding-top: 10px;
}

.home-btn {
  height: 45px;
  width: 175px;
  border-radius: 22.5px;
  background-color: #00A441;
  display: inline-block;
  padding-top: 6px;
}

.bikeparts-btn {
  height: 60px;
  width: 185px;
  border-radius: 30px;
  background-color: #FF9500;
  display: inline-block;
  padding-top: 4px;
}

.booking-btn {
  height: 60px;
  width: 185px;
  border-radius: 30px;
  background-color: #E7D326;
  display: inline-block;
  padding-top: 18px;
}

.booking-icon {
  height: auto;
  width: 145px;
}

.bikeparts-icon {
  height: auto;
  width: 125px;
}

.ebike-icon {
  height: auto;
  width: 135px;
}

.logo-icon {
  height: auto;
  width: 50px;
  margin-right: 10px;
}

.phone-icon {
  height: auto;
  width: 35px;
  margin-left: 12px;
  margin-top: -13px;
}

.footer {
  position: fixed;
  bottom: 0;
  height: 8vh;
  width: 1920px;
  background-color: #1D1D1D;
}
