.bodywrapper {
	min-height: 100vh;
	padding-top: 15.2rem;
  margin-top: -8vh;
	background-color: #ffffff;
	background-image: url('../../media/images/Group 4.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: top center;
	//background-attachment: fixed;
}

@media (min-width: 576px) and (max-width: 767.98px) {

}

@media (min-width: 768px) and (max-width: 991.98px) {

}

@media (min-width: 992px) and (max-width: 1199.98px) {

}

@media (min-width: 1200px) and (max-width: 1499.98px) {

}
